
import { Flex } from '@aws-amplify/ui-react';
import { useWindowDimensions } from 'react-native';

import { AppNavBar } from '../../ui-components';
import { Colors, Images } from '../../constants';

export default function Error404Screen() {
    const { height } = useWindowDimensions();

    return (
        <Flex direction="column" justifyContent="center" alignItems="center" style={{ height: height, backgroundColor: Colors.surface }} >
            <AppNavBar overrides={{ 
                'AppNavBar':  { width: '100%', position: 'absolute', top: 0 },
                'Logo30873174':  { src: process.env.REACT_APP_LOGO_URL, },
                'image':  { src: Images.USER_PLACEHOLDER, },
            }}/>
        </Flex>
    );
}