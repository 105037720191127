import React from 'react';
import { Flex, useAuthenticator } from '@aws-amplify/ui-react';
import { Route, Routes, BrowserRouter } from "react-router-dom";

import { Screens, ScreenNames } from './constants';
import useCachedResources from './hooks/useCacheResources';

function App() {
  const isLoadingComplete = useCachedResources();
  const { route } = useAuthenticator(context => [context.route]);

  if(!isLoadingComplete) return <Flex />

  return (
    <BrowserRouter>
      <Routes>
        {/* ROUTES */}
        <Route exact path={ScreenNames.HOME} element={<Screens.SubscriptionsStoreScreen/>} />
        <Route exact path={ScreenNames.SIGN_UP} element={<Screens.SignUpScreen />} />
        <Route exact path={ScreenNames.SIGN_IN} element={<Screens.SignUpScreen />} />
        <Route exact path={ScreenNames.OVERVIEW} element={route === 'authenticated' ? <Screens.OverviewScreen/> : <Screens.SignUpScreen/>} />
        <Route path={ScreenNames.SUBSCRIPTIONS} element={route === 'authenticated' ? <Screens.SubsciptionsScreen/> : <Screens.SignUpScreen/>} />
        <Route path={ScreenNames.PAYMENT_METHODS} element={route === 'authenticated' ? <Screens.PaymentMethodsScreen/> : <Screens.SignUpScreen/>} />
        <Route path={ScreenNames.BILLING_HISTORY} element={route === 'authenticated' ? <Screens.BillingHistoryScreen/> : <Screens.SignUpScreen/>} />
        <Route path={ScreenNames.SUBSCRIPTIONS_STORE} element={<Screens.SubscriptionsStoreScreen/>} />
        <Route path={ScreenNames.SUBSCRIBE} element={route === 'authenticated' ? <Screens.SubscribeScreen/> : <Screens.SignUpScreen/>} />
        <Route path={ScreenNames.INVOICE} element={<Screens.PayScreen/>} />
        <Route path={ScreenNames.TERMS} element={<Screens.TermsScreen/>} />
        <Route path={ScreenNames.ERROR404} element={<Screens.Error404Screen/>} />

        {/* NO ROUTE*/}
        <Route path="*" element={<Screens.Error404Screen/>} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
