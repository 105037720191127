import Loader from 'react-loading';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';
import { Modal, useWindowDimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  View,
  Text,
  Link,
  RadioGroupField,
  Radio,
  ToggleButton,
  Button,
  IconError,
  Image
} from '@aws-amplify/ui-react';

import _ from '../../utils/lodash';
import Footer from '../../components/Footer';
import { getClientIP } from '../../utils/client';
import { getQueryStringParameter } from '../../utils/html';
import { loadVaultToken, storeVaultToken } from '../../utils/vault';
import PaymentType from '../../components/Modals/CreditCard/PaymentType';
import { Colors, Images, Strings, ScreenNames } from '../../constants';
import {
  AppNavBar,
  BlankOverviewBox,
  SubscriptionLineItemInset
} from '../../ui-components';
import {
  loadCustomerInvoice,
  createPaymentMethod,
  loadCustomerPaymentMethods,
  loadCustomer,
  payInvoice
} from '../../utils/rest';

export default function PayScreen() {
  const navigate = useNavigate();

  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(() => {
    if (width < 768) return true;
    else return false;
  });

  useEffect(() => {
    if (width < 768) setIsMobile(true);
    else return setIsMobile(false);
  }, [width]);

  //Get query params
  const [user, setUser] = useState();

  const [invoice, setInvoice] = useState({ lineItems: [] });
  const [invoiceId, setInvoiceId] = useState(
    getQueryStringParameter('invoiceId')
  );

  const [customer, setCustomer] = useState({});
  const [customerId, setCustomerId] = useState(
    getQueryStringParameter('customerId')
  );
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [vaultToken, setVaultToken] = useState(null);
  const [vaultUrl] = useState(_.get(process.env, 'REACT_APP_IFRAME_URL'));
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const [step, setStep] = useState('pay'); //pay, spt, cc, dda, ps, error

  const [name, setName] = useState('');

  const [address, setAddress] = useState('');

  const [paymentToken, setPaymentToken] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);

  //PRODUCT
  const [modalView] = useState(false);
  const [ddaPressed, setDDAPressed] = useState(false);
  const [creditPressed, setCreditPressed] = useState(false);

  const [cardFormLoading, setCardFormLoading] = useState(false);
  const addCardSubmit = async (event) => {
    event.preventDefault();

    //Submit form
    setCardFormLoading(true);

    let finalizedCard = {};
    //API
    try {
      console.log({ vaultToken });
      finalizedCard = await storeVaultToken(vaultToken);

      console.log({ finalizedCard });
    } catch (vaultError) {
      console.log({ message: 'VAULT ERROR', vaultError });

      return false;
    }

    try {
      let paymentMethod = {
        token: _.get(finalizedCard, 'data.token'),
        last: _.get(finalizedCard, 'data.last'),
        issuer_id: _.get(finalizedCard, 'data.issuer_id'),
        expiration: _.get(finalizedCard, 'data.expiration'),
        brand: _.get(finalizedCard, 'data.brand'),
        customerId: _.get(customer, 'id'),
        primary: true,
        scheme: 'CREDIT',
        category: 'CREDIT',
        billingDetails: {
          address: _.get(finalizedCard, 'data.address'),
          address2: _.get(finalizedCard, 'data.address2'),
          country: _.get(finalizedCard, 'data.country', 'USA'),
          locality: _.get(finalizedCard, 'data.city'),
          region: _.get(finalizedCard, 'data.state'),
          postal: _.get(finalizedCard, 'data.zip')
        }
      };

      paymentMethod = _.omit(paymentMethod, ['scheme', 'type']);

      console.log({ paymentMethod });

      createPaymentMethod(paymentMethod, (pm) => {
        const paymentMethodObject = _.get(pm, 'storePaymentMethod.0');

        if (_.get(paymentMethodObject, 'code') === '432') {
          setCardFormLoading(false);
          errorToast(
            'Failed to Authorize Card (432): Please try another form of payment.'
          );
          console.error('Card Authorization Failed:', paymentMethodObject);
          setCardFormLoading(false);
          return false;
        }

        console.log('createPaymentMethod Results', {
          createResponse: pm,
          original: paymentMethod,
          paymentMethodObject
        });

        _.set(paymentMethod, 'id', _.get(paymentMethodObject, 'id'));

        if (user) {
          loadCustomerPaymentMethods(customer.id, setPaymentMethods);
        } else {
          setPaymentMethods([paymentMethod]);
          setPaymentToken(paymentMethod.token);
        }

        setSelectedPaymentMethod(paymentMethodObject);
        setCardFormLoading(false);
        setStep('pay');
      });

      //successToast()
    } catch (e) {
      console.log(e);
      setCardFormLoading(false);
      errorToast();
    }
  };

  const [bankFormLoading, setBankFormLoading] = useState(false);
  const addBankSubmit = async (event) => {
    event.preventDefault();

    //Submit form
    setBankFormLoading(true);

    let finalizedBank = {};
    //API
    try {
      console.log({ vaultToken });
      finalizedBank = await storeVaultToken(vaultToken);

      console.log({ finalizedBank });
    } catch (vaultError) {
      console.log({ message: 'VAULT ERROR', vaultError });

      return false;
    }

    try {
      let paymentMethod = {
        primary: true,
        customerId: _.get(customer, 'id'),
        last: _.get(finalizedBank, 'data.last'),
        type: _.get(finalizedBank, 'data.type'),
        token: _.get(finalizedBank, 'data.token'),
        brand: _.get(finalizedBank, 'data.brand'),
        routing: _.get(finalizedBank, 'data.routing'),
        scheme: _.toUpper(_.get(finalizedBank, 'data.scheme', 'dda')),
        category: _.toUpper(_.get(finalizedBank, 'data.scheme', 'dda')),
        billingDetails: {
          accountHolder: {
            firstName: _.get(finalizedBank, 'data.firstName'),
            lastName: _.get(finalizedBank, 'data.lastName')
          }
        }
      };

      paymentMethod = _.omit(paymentMethod, ['scheme']);

      console.log({ paymentMethod });

      createPaymentMethod(paymentMethod, (pm) => {
        const paymentMethodObject = _.get(
          pm,
          'storePaymentMethod.0',
          _.get(pm, 'storeDDAPaymentMethod.0')
        );

        console.log('createPaymentMethod Results', {
          createResponse: pm,
          original: paymentMethod,
          paymentMethodObject
        });

        _.set(paymentMethod, 'id', _.get(paymentMethodObject, 'id'));

        if (user) {
          loadCustomerPaymentMethods(customer.id, setPaymentMethods);
        } else {
          setPaymentMethods([paymentMethod]);
          setPaymentToken(paymentMethod.token);
        }

        setSelectedPaymentMethod(paymentMethodObject);
        setBankFormLoading(false);
        setStep('pay');
      });

      //successToast()
    } catch (error) {
      setBankFormLoading(false);
      console.error(error);
      errorToast(error);
    }
  };

  const signOut = async () => {
    try {
      console.log('signin out');
      let User = await Auth.signOut();
      setUser(User);
      window.location.reload();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const containerWidth = isMobile ? width / 1.1 : width / 1.2;

  // const addSelectedInvoice = (val, invoice) => {
  //     //if true, add invoice to selected
  //     if(val){
  //         let invoices = [...selectedInvoices]
  //         invoices.push(invoice)
  //         setSelectedInvoices(invoices)
  //     } else {
  //         //if false, remove invoice from selected
  //         let found = selectedInvoices.filter((i) => i.id !== invoice.id)
  //         setSelectedInvoices(found)
  //     }
  // };

  //
  const [payFormLoading, setPayFormLoading] = useState(false);
  const payNow = async () => {
    try {
      setPayFormLoading(true);

      const IP = await getClientIP();
      console.log({ IP });
      console.log({ paymentToken });

      const paymentMethod = _.find(
        paymentMethods,
        (i) => i.token === paymentToken
      );

      const payData = {
        amount: invoice.balance,
        currency: invoice.lineItems[0].currency,
        customerId: customer.id,
        invoiceId: invoice.id,
        token: paymentToken,
        paymentMethodIds: [paymentMethod.id],
        confirmation: {
          send: true,
          email: customer.email
        },
        metadata: [{ key: 'created_origin', value: IP.data.ip }]
      };

      console.log({ payData });

      const invoiceResponse = await payInvoice(invoice.id, payData);
      console.log({ invoiceResponse });

      if (invoiceResponse.success) setStep('ps');
      else setStep('error');

      setPayFormLoading(false);
    } catch (e) {
      console.log('pay error', e.response);
      alert(
        _.replace(
          _.get(e, 'response.data.error', 'Error with payment'),
          'Error: ',
          ''
        )
      );
      setPayFormLoading(false);
    }
  };

  // const productItems = (products) => {
  //     return (
  //         products.map((i, idx) => {
  //             if(isMobile)
  //             return (
  //                 <Flex key={idx} direction={'row'} justifyContent={'flex-end'}>
  //                     <SubscriptionLineItemInset marginBottom={5} right={0} width={'90%'} overrides={{
  //                         'Title': { children: i.product.title },
  //                         'Description': { children: i.product.description, width: '100%'},
  //                         'Amount': { children: `$${i.amount}`, right: 0, textAlign: 'left', fontFamily: 'Inter-Light'},
  //                     }} />
  //                 </Flex>
  //             )
  //             else
  //             return (
  //                 <Flex key={idx} direction={'row'} justifyContent={'flex-end'}>
  //                     <SubscriptionLineItemInset marginBottom={15} right={0} width={'90%'} overrides={{
  //                         'Title': { children: i.product.title},
  //                         'Description': { children: i.product.description},
  //                         'Amount': { children: `$${i.amount}`,  fontFamily: 'Inter-Light'},
  //                     }} />
  //                 </Flex>
  //             )
  //         })
  //     )
  // }

  //ALERTS
  // const successToast = () => toast.success(Strings.SUCCESSFULLY_UPDATED, { position: 'top-center', theme: 'colored'});
  const errorToast = (errorMessage = false) =>
    toast.error(errorMessage || Strings.ERROR_OCCURED, {
      position: 'top-center',
      theme: 'colored'
    });

  //ROUTES
  const goToOverview = () => navigate(ScreenNames.OVERVIEW);
  // const gotToSubscriptions = () => navigate(ScreenNames.SUBSCRIPTIONS)
  // const goToPaymentMethods = () => navigate(ScreenNames.PAYMENT_METHODS)
  // const goToBillingHistory = () => navigate(ScreenNames.BILLING_HISTORY)
  const goToSignIn = () => navigate(ScreenNames.SIGN_IN);
  const goToClientPage = () =>
    (window.location.href = process.env.REACT_APP_CLIENT_URL);

  //HELPERS
  const getFriendlyAddress = (cust) => {
    const segments = [
      _.get(cust, 'address', ''),
      _.get(cust, 'address2', ''),
      _.get(cust, 'locality', ''),
      _.get(cust, 'region', ''),
      _.get(cust, 'postal', '')
    ];

    return segments.join(', ').replace(/, ,/g, ', ');
  };

  const [cardIframeLoaded, setCardIframeLoaded] = useState(false);
  const vaultCardIframe = () => {
    return (
      <>
        {!cardIframeLoaded && (
          <Flex
            direction={'column'}
            width={'100%'}
            justifyContent="center"
            alignItems={'center'}
            height={'600px'}
          >
            <Loader
              type="bubbles"
              color={Colors.loader}
              width={50}
              height={50}
            />
          </Flex>
        )}

        <iframe
          title="card-iframe"
          onLoad={() => setCardIframeLoaded(true)}
          style={{
            width: '100%',
            height: '400px',
            marginLeft: '-15px',
            border: 'none'
          }}
          scrolling="no"
          src={`${vaultUrl}/card/${vaultToken}?address=true&firstName=${customer.firstName}&lastName=${customer.lastName}`}
        ></iframe>
      </>
    );
  };

  const [bankIframeLoaded, setBankIframeLoaded] = useState(false);
  const vaultBankIframe = () => {
    return (
      <>
        {!bankIframeLoaded && (
          <Flex
            direction={'column'}
            width={'100%'}
            justifyContent="center"
            alignItems={'center'}
            height={'100px'}
          >
            <Loader
              type="bubbles"
              color={Colors.loader}
              width={50}
              height={50}
            />
          </Flex>
        )}

        <iframe
          title="bank-iframe"
          onLoad={() => setBankIframeLoaded(true)}
          style={{
            width: '100%',
            height: '300px',
            marginLeft: '-15px',
            border: 'none'
          }}
          scrolling="no"
          src={`${vaultUrl}/bank/${vaultToken}?firstName=${customer.firstName}&lastName=${customer.lastName}`}
        ></iframe>
      </>
    );
  };
  const hasPaymentMethods = _.size(_.first(paymentMethods)) > 0;

  const isPaymentMethodValid = (category) => {
    const { REACT_APP_THRESHOLD_ACH_MIN, REACT_APP_THRESHOLD_ACH_MAX } = process.env;
    const { REACT_APP_THRESHOLD_CC_MIN, REACT_APP_THRESHOLD_CC_MAX } = process.env;
    if (category === 'CREDIT') {
      return (
        (!REACT_APP_THRESHOLD_CC_MIN ||
          Number(REACT_APP_THRESHOLD_CC_MIN) <= invoice.balance) &&
        (!REACT_APP_THRESHOLD_CC_MAX ||
          Number(REACT_APP_THRESHOLD_CC_MAX) >= invoice.balance)
      );
    } else if (category === 'DDA') {
      return (
        (!REACT_APP_THRESHOLD_ACH_MIN ||
          Number(REACT_APP_THRESHOLD_ACH_MIN) <= invoice.balance) &&
        (!REACT_APP_THRESHOLD_ACH_MAX ||
          Number(REACT_APP_THRESHOLD_ACH_MAX) >= invoice.balance)
      );
    } else {
      return false;
    }
  };

  const isFormValid = () => {
    if (!paymentToken) {
      return false;
    }
    const paymentMethod = _.find(paymentMethods, { token: paymentToken });
    if (paymentMethod) {
      return isPaymentMethodValid(paymentMethod.category);
    }
    if (paymentToken && !paymentMethod) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      document.title = Strings.PAY_INVOICE;
      // loadVaultToken(setVaultToken);
      let User = await Auth.currentAuthenticatedUser();
      setUser(User);
    })();
  }, []);

  useEffect(() => {
    const handleIframeEvent = (event) => {
      console.log({ message: 'From postMessage API', event });

      const eventData = _.get(event, 'data');
      const eventObject = _.isJson(eventData)
        ? JSON.parse(eventData)
        : eventData;

      console.log({ eventObject });

      if (_.get(eventObject, 'detail.message') === 'complete')
        setSubmitDisabled(false);
      else setSubmitDisabled(true);
    };

    window.addEventListener('message', handleIframeEvent);

    // cleanup this component
    return () => {
      window.removeEventListener('message', handleIframeEvent);
    };
  }, []);

  useEffect(() => {
    if (step === 'cc' || step === 'dda') loadVaultToken(setVaultToken);
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    (async () => {
      console.log({ user });

      setInvoiceId(_.cloneDeep(getQueryStringParameter('invoiceId')));
      setCustomerId(_.cloneDeep(getQueryStringParameter('customerId')));
      await loadCustomer(setCustomer);
      //loadProduct(selectedProduct, setProduct);
    })();
  }, [user]);

  useEffect(() => {
    console.log({ message: 'Loading Invoice ...', customerId, invoiceId });

    loadCustomerInvoice({ customerId, invoiceId }, setInvoice);
  }, [customerId, invoiceId]);

  useEffect(() => {
    console.log({ message: 'Invoice Loaded.', invoice });

    console.log({
      message: 'Setting Customer ...',
      customer: _.get(invoice, 'customer', {})
    });

    setCustomer(_.get(invoice, 'customer', {}));
  }, [invoice]);

  useEffect(() => {
    if (customer) {
      console.log({ message: 'Customer Set.', customer });

      setAddress(getFriendlyAddress(customer));
      setName(`${_.get(customer, 'firstName')} ${_.get(customer, 'lastName')}`);
      if (user) loadCustomerPaymentMethods(customer.id, setPaymentMethods);
    }
  }, [customer, user]);

  useEffect(() => {
    ///setPaymentMethods([selectedPaymentMethod])
  }, [selectedPaymentMethod]);

  useEffect(() => {
    console.log({ paymentMethods });
  }, [paymentMethods]);

  return (
    <Flex
      direction="column"
      alignItems={'center'}
      height={modalView ? '100vh' : null}
      minHeight="100vh"
      backgroundColor={Colors.surface}
      width={'100%'}
      overflow={'hidden'}
    >
      {/* <View
        backgroundColor={Colors.background}
        width={'100%'}
        position={'absolute'}
        height={'30%'}
      >
        <Text
                    fontFamily="Inter-ExtraBold"
                    fontSize="32px"
                    fontWeight="700"
                    color="#fff"
                    position='absolute'
                    bottom={100}
                    left={30}
                    lineHeight="40px"
                    children={`${Strings.INVOICE} ${invoices[0].id}`} />
      </View> */}
      <AppNavBar
        overrides={{
          AppNavBar: { width: '100%', backgroundColor: Colors.secondary },
          user: {
            fontFamily: 'Inter-Regular',
            color: Colors.headerText,
            children:
              _.get(process.env, 'REACT_APP_ALLOW_SIGN_IN') === 'false'
                ? null
                : user
                ? Strings.SIGN_OUT
                : Strings.SIGN_IN,
            style: { cursor: 'pointer' },
            onClick: () => (user ? signOut() : goToSignIn())
          },
          Logo30873174: {
            src: process.env.REACT_APP_LOGO_URL,
            onClick: () => goToOverview(),
            style: { cursor: 'pointer' }
          },
          image: { src: Images.USER_PLACEHOLDER, style: { display: 'none' } }
        }}
      />

      <Flex
        direction={'column'}
        width={isMobile ? containerWidth : containerWidth / 2}
        flex={1}
        marginTop={isMobile ? 30 : 30}
        marginBottom={30}
      >
        {/* PAY NOW */}
        {step === 'pay' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            {!invoice.id ? (
              <Flex direction={'column'} width={'100%'} alignItems="center">
                <Loader
                  type="bubbles"
                  color={Colors.loader}
                  width={75}
                  height={75}
                />
              </Flex>
            ) : (
              <Flex direction={'column'} width={'100%'}>
                <Text
                  fontFamily="Inter"
                  fontSize="32px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="40px"
                  children={Strings.INVOICE}
                />

                <Flex direction={'row'} rowSpan={2} marginTop={30}>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.DATE}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={new Date(invoice.created).toLocaleDateString()}
                      marginTop={10}
                    />
                  </View>
                </Flex>
                <Flex direction={'row'} rowSpan={2} marginTop={10}>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.NAME}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={name}
                      marginTop={10}
                    />
                  </View>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.EMAIL}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={_.get(customer, 'email')}
                      marginTop={10}
                    />
                  </View>
                </Flex>
                <Flex direction={'row'} rowSpan={2} marginTop={10}>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.ADDRESS}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={address}
                      marginTop={10}
                    />
                  </View>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={`${Strings.INVOICE} #`}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={invoice.number}
                      marginTop={10}
                    />
                  </View>
                </Flex>

                <Flex direction={'column'} marginTop={40}>
                  <Text
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="300"
                    lineHeight="20px"
                    children={Strings.SUBSCRIPTION}
                  />
                </Flex>

                <Flex
                  direction={'column'}
                  width={'100%'}
                  marginTop={10}
                  marginBottom={0}
                  style={{
                    'border-bottom': '1px solid #000'
                  }}
                >
                  <Flex direction={'column'}>
                    {invoice.lineItems.map((i, idx) => {
                      return (
                        <SubscriptionLineItemInset
                          key={idx}
                          right={0}
                          width={'100%'}
                          marginBottom={20}
                          overrides={{
                            SubscriptionLineItem: { width: '10px' },
                            Title: {
                              children: i.productDescription,
                              fontSize: '16px',
                              maxWidth: '90%',
                              flexWrap: 'wrap'
                            },
                            Description: { children: '', width: '100%'},
                            Amount: {
                              children: `$${Number(_.get(i, 'amount')).toFixed(
                                2
                              )}`,
                              left: 220,
                              textAlign: 'right',
                              fontSize: '16px'
                            }
                          }}
                        />
                      );
                    })}
                  </Flex>
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  direction={'row'}
                  width={'100%'}
                >
                  <Text
                    fontFamily="Inter-Bold"
                    fontSize="18px"
                    fontWeight="200"
                    lineHeight="24px"
                    children={Strings.SUBTOTAL}
                  />
                  <Text
                    fontFamily="Inter-ExtraBold"
                    fontSize="18px"
                    fontWeight="300"
                    lineHeight="18px"
                    alignSelf={'flex-end'}
                    children={`$${(
                      Number(invoice.total) - Number(invoice.totalTax || 0)
                    ).toFixed(2)}`}
                  />
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  direction={'row'}
                  width={'100%'}
                >
                  <Text
                    fontFamily="Inter-Bold"
                    fontSize="18px"
                    fontWeight="200"
                    lineHeight="24px"
                    children={Strings.SALES_TAX}
                  />
                  <Text
                    fontFamily="Inter-ExtraBold"
                    fontSize="18px"
                    fontWeight="300"
                    lineHeight="18px"
                    alignSelf={'flex-end'}
                    children={`$${Number(invoice.totalTax || 0).toFixed(2)}`}
                  />
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  direction={'row'}
                  width={'100%'}
                >
                  <Text
                    fontFamily="Inter-Bold"
                    fontSize="18px"
                    fontWeight="200"
                    lineHeight="24px"
                    children={`${Strings.TOTAL} (USD)`}
                  />
                  <Text
                    fontFamily="Inter-ExtraBold"
                    fontSize="18px"
                    fontWeight="300"
                    lineHeight="18px"
                    alignSelf={'flex-end'}
                    children={`$${Number(invoice.total).toFixed(2)}`}
                  />
                </Flex>

                <Flex
                  justifyContent={'space-between'}
                  direction={'row'}
                  width={'100%'}
                  marginTop={10}
                  marginBottom={15}
                >
                  <Text
                    fontFamily="Inter-ExtraBold"
                    fontSize="28px"
                    fontWeight="300"
                    lineHeight="20px"
                    children={`${Strings.UNPAID_BALANCE} (USD)`}
                  />
                  <Text
                    fontFamily="Inter-ExtraBold"
                    fontSize="28px"
                    fontWeight="300"
                    lineHeight="20px"
                    alignSelf={'flex-end'}
                    children={`$${Number(invoice.balance).toFixed(2)}`}
                  />
                </Flex>

                {hasPaymentMethods && (
                  <View>
                    <RadioGroupField
                      label={Strings.SELECT_PAYMENT_METHOD}
                      value={paymentToken}
                      onChange={(e) => setPaymentToken(e.target.value)}
                    >
                      {_.map(paymentMethods, (i) => (
                        isPaymentMethodValid(i.category) && <Radio
                          key={_.get(i, 'token', '')}
                          value={_.get(i, 'token', '')}
                          onChange={(e) => setPaymentToken(e.target.value)}
                        >
                          {i.brand && (
                            <View style={{ marginLeft: 15 }}>
                              <PaymentType
                                customerId={customerId}
                                paymentMethodId={i.id}
                                pm={i}
                              />
                            </View>
                          )}
                          {/* {_.get(i, 'category', '') === 'DDA' && <Text children={`${Strings.BANK} ${Strings.ENDING_IN.toLowerCase()} ${_.get(i, 'last', 'XXXX')}`} />} */}
                        </Radio>
                      ))}
                    </RadioGroupField>
                    <Button
                      marginTop="20px"
                      variation="link"
                      fontFamily="Inter"
                      fontSize="15px"
                      fontWeight="300"
                      lineHeight="20px"
                      textDecoration={'underline'}
                      children={`Change your payment method`}
                      onClick={() => setStep('spt')}
                    />
                  </View>
                )}

                {invoice.confirmation && invoice.confirmation.attachment && (
                  <Flex
                  direction={'row'}
                  width={'100%'}
                  alignItems={'center'}
                  marginTop={10}
                  >
                    <Text
                      fontFamily="Inter"
                      color="rgba(13,26,38,1)"
                      children={Strings.ATTACHMENT + ':'}
                      marginRight={10}
                    />
                    <Link href={invoice.confirmation.attachment.base64} target="_blank" download={invoice.confirmation.attachment.name}>{invoice.confirmation.attachment.name}</Link>
                  </Flex>
                )}

                {invoice.balance !== 0 ? (
                  hasPaymentMethods ? (
                    <View marginTop={30}>
                      <Button
                        variation={paymentToken ? 'primary' : ''}
                        onClick={payNow}
                        isLoading={payFormLoading}
                        hidden={payFormLoading || !isFormValid()}
                        isDisabled={payFormLoading || !isFormValid()}
                        loadingText={
                          payFormLoading ? Strings.PROCESSING + '...' : ''
                        }
                        children={Strings.PAY_NOW}
                        width={'100%'}
                      />
                    </View>
                  ) : (
                    <Flex direction={'column'} marginTop={15}>
                      <Button
                        variation="primary"
                        children={Strings.ADD_PAYMENT_METHOD}
                        onClick={() => setStep('spt')}
                      />
                    </Flex>
                  )
                ) : null}
              </Flex>
            )}
          </BlankOverviewBox>
        )}

        {/* SELECT PAYMENT TYPE */}
        {step === 'spt' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex direction={'column'} width={'100%'}>
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.SELECT_PAYMENT_TYPE}
              />

              <View>
                <Link
                  onClick={() => setStep('pay')}
                  children={Strings.INVOICE}
                />{' '}
                /{' '}
                <Link color={'black'} children={Strings.SELECT_PAYMENT_TYPE} />
              </View>

              <Flex direction={'row'} rowSpan={2} marginTop={30}>
                {isPaymentMethodValid('CREDIT') && <ToggleButton
                  width={'50%'}
                  variation="primary"
                  size="large"
                  value="credit"
                  children={Strings.CREDIT_CARD}
                  onChange={(value) => {
                    setCreditPressed(true);
                    setDDAPressed(false);
                    setStep('cc');
                  }}
                  isPressed={creditPressed}
                />}
                {isPaymentMethodValid('DDA') && <ToggleButton
                  width={'50%'}
                  variation="primary"
                  size="large"
                  value="dda"
                  children={Strings.BANK_ACCOUNT}
                  onChange={(value) => {
                    setCreditPressed(false);
                    setDDAPressed(true);
                    setStep('dda');
                  }}
                  isPressed={ddaPressed}
                />}
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}

        {/* CREDIT CARD */}
        {step === 'cc' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              as={'form'}
              onSubmit={(e) => addCardSubmit(e)}
              direction={'column'}
              width={'100%'}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.CREDIT_CARD}
              />

              <View>
                <Link
                  onClick={() => setStep('pay')}
                  children={Strings.INVOICE}
                />{' '}
                /{' '}
                <Link
                  onClick={() => setStep('spt')}
                  children={Strings.SELECT_PAYMENT_TYPE}
                />{' '}
                / <Link color={'black'} children={Strings.CREDIT_CARD} />
              </View>
              
              <Image src={Images.CARD_BRANDS} width={width / 9} />

              <Flex direction={'column'} rowSpan={5} marginTop={30}>
                <View width={'100%'} marginTop={-30}>
                  {/* Card Iframe goes here */}
                  {vaultCardIframe()}
                </View>
              </Flex>

              {submitDisabled && (
                <Flex
                  direction={'column'}
                  justifyContent="center"
                  width={'100%'}
                >
                  <View justifyContent="center" width={'100%'}>
                    Please complete required fields above.{' '}
                    <small>(Required fields are marked with a *)</small>
                  </View>
                </Flex>
              )}

              <Flex
                direction={'row'}
                marginTop={35}
                justifyContent="center"
                width={'100%'}
              >
                <Button
                  width="50%"
                  type="submit"
                  variation="primary"
                  onClick={addCardSubmit}
                  isDisabled={submitDisabled}
                  isLoading={cardFormLoading}
                  loadingText="Please Wait..."
                >
                  {Strings.NEXT}
                </Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}

        {/* BANK ACCOUNT */}
        {step === 'dda' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              as={'form'}
              onSubmit={(e) => addBankSubmit(e)}
              direction={'column'}
              width={'100%'}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.BANK_ACCOUNT}
              />

              <View>
                <Link
                  onClick={() => setStep('pay')}
                  children={Strings.INVOICE}
                />{' '}
                /{' '}
                <Link
                  onClick={() => setStep('spt')}
                  children={Strings.SELECT_PAYMENT_TYPE}
                />{' '}
                / <Link color={'black'} children={Strings.BANK_ACCOUNT} />
              </View>

              <Flex direction={'column'} rowSpan={2} marginTop={30}>
                <View width={'100%'} marginTop={-30}>
                  {/* Bank Iframe goes here */}
                  {vaultBankIframe()}
                </View>
              </Flex>

              {submitDisabled && (
                <Flex
                  direction={'column'}
                  justifyContent="center"
                  width={'100%'}
                >
                  <View justifyContent="center" width={'100%'}>
                    Please complete required fields above.{' '}
                    <small>(Required fields are marked with a *)</small>
                  </View>
                </Flex>
              )}

              <Flex
                direction={'row'}
                marginTop={35}
                justifyContent="center"
                width={'100%'}
              >
                <Button
                  width="50%"
                  type="submit"
                  variation="primary"
                  onClick={addBankSubmit}
                  isDisabled={submitDisabled}
                  isLoading={bankFormLoading}
                  loadingText="Loading..."
                >
                  {Strings.NEXT}
                </Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}

        {/* PAYMENT SUCCESSFUL */}
        {step === 'ps' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              as={'form'}
              onSubmit={(e) => addBankSubmit(e)}
              direction={'column'}
              width={'100%'}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.PAYMENT_SUCCESSFUL}
              />

              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.01px"
                children={Strings.THANK_YOU_FOR_YOUR_PAYMENT}
              />

              {/* PAYMENT SUCCESSFUL */}
              <Flex
                direction={'row'}
                rowSpan={2}
                marginTop={30}
                justifyContent="center"
                alignItems={'center'}
              >
                <IoCheckmarkDoneOutline color="green" size={125} />
              </Flex>

              <Flex
                direction={'row'}
                marginTop={35}
                justifyContent="flex-end"
                width={'100%'}
              >
                <Button
                  width={120}
                  variation="primary"
                  onClick={() => goToClientPage()}
                >
                  {Strings.DONE}
                </Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}

        {/* PAYMENT FAILED */}
        {step === 'error' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              as={'form'}
              onSubmit={(e) => addBankSubmit(e)}
              direction={'column'}
              width={'100%'}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.PAYMENT_FAILED}
              />

              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.01px"
                children={Strings.UNABLE_TO_PROCESS_PAYMENT}
              />

              {/* PAYMENT SUCCESSFUL */}
              <Flex
                direction={'row'}
                rowSpan={2}
                marginTop={30}
                justifyContent="center"
                alignItems={'center'}
              >
                <IconError color="red" size={125} />
              </Flex>

              <Flex
                direction={'row'}
                marginTop={35}
                justifyContent="flex-end"
                width={'100%'}
              >
                <Button
                  width={120}
                  variation="primary"
                  onClick={() => setStep('pay')}
                >
                  {Strings.TRY_AGAIN}
                </Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}
      </Flex>

      <Modal visible={modalView} animationType="slide" transparent>
        <BlankOverviewBox
          overrides={{
            BlankOverviewBox: {
              width: isMobile ? width / 1.1 : width / 3,
              alignSelf: 'center',
              marginTop: isMobile ? 20 : 50,
              alignContent: 'center',
              justifyContent: 'center'
            }
          }}
        >
          {/* {chargesModalVisible && charges(upcomingCharges)} 
                        {updatePaymentMethodVisible && updatePaymentMethod()}
                        {updatePersonalInformationVisible && updatePersonalInformationForm()}
                        {updateEmailVisible && updateEmailForm()}
                        {updatePhoneVisible && updatePhoneForm()}
                        {updatePasswordVisible && updatePasswordForm()}
                        {cancelSubscriptionVisible && cancelSubscriptionForm()} */}
          <Text>lorem epsum</Text>
        </BlankOverviewBox>
      </Modal>

      {modalView && (
        <View
          height={'100%'}
          width={width}
          backgroundColor={'rgba(0,0,0,0.5)'}
          top={0}
          left={0}
          bottom={0}
          position="absolute"
        />
      )}

      <ToastContainer />

      <Footer />
    </Flex>
  );
}
