import _ from 'lodash';
import Loader from 'react-loading';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'react-native';
import { Flex, View, Text, Button } from '@aws-amplify/ui-react';

import Footer from '../../components/Footer';
import Error404Screen from '../ErrorScreen/404';
import { AppNavBar, BlankOverviewBox } from '../../ui-components';
import { Colors, Images, Strings, ScreenNames } from '../../constants';
import {
  loadCustomer,
  loadProducts,
  loadSubscriptions,
} from '../../utils/rest';

export default function PayScreen() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(() => {
    if (width < 768) return true;
    else return false;
  });
  useEffect(() => {
    if (width < 768) setIsMobile(true);
    else return setIsMobile(false);
  }, [width]);

  //const { user } = useAuthenticator(context => console.log({ context}));
  // const signOut = async () => {
  //     try {
  //         await Auth.signOut({ global: true });
  //     } catch (error) {
  //         console.log({ message: 'Error when signing out: ', error });
  //     }
  // }

  const [customer, setCustomer] = useState({});

  const containerWidth = isMobile ? width / 1.1 : width / 1.2;

  //SUBSCRIPTIONS

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false);

  const [products, setProducts] = useState([]);

  //ALERTS
  // const successToast = () => toast.success(Strings.SUCCESSFULLY_UPDATED, { position: 'top-center', theme: 'colored'});
  // const errorToast = () => toast.error(Strings.ERROR_OCCURED, { position: 'top-center', theme: 'colored'});

  //ROUTES
  // const goToPay = (invoiceId) => navigate(`${ScreenNames.INVOICE}?invoiceId=${invoiceId}`)
  const goToSubscribe = (product) =>
    navigate(`${ScreenNames.SUBSCRIBE}?productId=${product.id}`);
  const goToOverview = () => navigate(ScreenNames.OVERVIEW);
  // const gotToSubscriptions = () => navigate(ScreenNames.SUBSCRIPTIONS)
  // const goToPaymentMethods = () => navigate(ScreenNames.PAYMENT_METHODS)
  // const goToBillingHistory = () => navigate(ScreenNames.BILLING_HISTORY)
  // const goToSignup = (product) => navigate(`${ScreenNames.SIGN_UP}?productId=${product.id}`)
  const goToSignIn = () => navigate(`${ScreenNames.SIGN_IN}`);
  const goToPMComfort = () =>
    (window.location.href = process.env.REACT_APP_CLIENT_URL);

  //HELPERS
  // const hasSignedUp = () => _.has(user, 'email') === true;
  // const notSignedUp = () => _.has(user, 'email') === false;
  // const notSubscribed = () => hasSignedUp() && subscriptionsLoaded && _.size(subscriptions) === 0;
  // const hasSubscribed = () => hasSignedUp() && subscriptionsLoaded && _.size(subscriptions) > 0;

  useEffect(() => {
    document.title = Strings.ENROLL_NOW;
    async function loadData() {
      await loadProducts(setProducts);
      setLoading(false);
    }
    loadData();
  }, []);

  useEffect(() => {
    (async () => {
      const User = await Auth.currentAuthenticatedUser();
      setUser(User.attributes);
      if (User) navigate(ScreenNames.SUBSCRIPTIONS);
    })();
  }, [navigate]);

  useEffect(() => {
    loadCustomer(setCustomer);
  }, [user]);

  useEffect(() => {
    async function loadData() {
      await loadSubscriptions(customer, setSubscriptions);
    }
    loadData();
  }, [customer]);

  useEffect(() => {
    setSubscriptionsLoaded(true);
  }, [subscriptions, subscriptionsLoaded]);

  useEffect(() => {
    //console.log({ products, loading })
  }, [products]);

  // if(hasSubscribed()) return (
  //   <Navigate to={ScreenNames.SUBSCRIPTIONS} replace={true}/>
  // )

  if (_.get(process.env, 'REACT_APP_ALLOW_SIGN_IN') === 'false')
    return <Error404Screen />;

  return (
    <>
      <Flex
        backgroun
        direction="column"
        justifyContent={'center'}
        alignItems={'center'}
        backgroundColor={Colors.surface}
        width={'100%'}
        overflow={'hidden'}
      >
        <AppNavBar
          overrides={{
            AppNavBar: {
              width: '100%',
              children: `Sign out`,
              backgroundColor: Colors.primary,
            },
            user: {
              color: Colors.headerText,
              fontFamily: 'Inter-Regular',
              children: 'Sign In',
              display: _.get(process.env, 'REACT_APP_ALLOW_SIGN_IN') === 'false' ? 'none' : null,
              onClick: () => (user ? goToOverview() : goToSignIn()),
              style: { cursor: 'pointer' },
            },
            Logo30873174: {
              src: process.env.REACT_APP_LOGO_URL,
              onClick: () => goToPMComfort(),
              style: { cursor: 'pointer' },
            },
            image: { src: Images.USER_PLACEHOLDER, style: { display: 'none' } },
          }}
        />

        <Flex
          direction={'column'}
          justifyContent="center"
          alignItems={'center'}
          width={isMobile ? containerWidth : containerWidth / 2}
          marginTop={isMobile ? 50 : 30}
          marginBottom={0}
        >
          <View width={width / 2.5}>
            <Text
              fontFamily="Inter-ExtraBold"
              textAlign={'left'}
              fontSize={isMobile ? '17px' : '35px'}
              fontWeight="700"
              color="#000"
              bottom={100}
              left={30}
              lineHeight="40px"
              children={Strings.SELECT_MAINTENANCE_PLAN}
            />
            <Text
              fontFamily="Inter-Regular"
              textAlign={'left'}
              fontSize={isMobile ? '15px' : '17px'}
              fontWeight="700"
              color="#000"
              bottom={100}
              left={30}
              marginTop={30}
              children={Strings.SELECT_MAINTENANCE_PLAN_DESCRIPTION}
            />
          </View>

          {loading ? (
            <Flex
              direction={'column'}
              width={'100%'}
              height={500}
              justifyContent="center"
              alignItems={'center'}
            >
              <Loader
                type="bubbles"
                color={Colors.primary}
                width={75}
                height={75}
              />
            </Flex>
          ) : (
            <View marginBottom={30}>
              <Flex>
                {_.map(products, (product) => {
                  return (
                    <BlankOverviewBox
                      key={product.id}
                      overrides={{
                        BlankOverviewBox: {
                          width: isMobile ? containerWidth : width / 4,
                          minHeight: 500,
                          borderRadius: '5px 5px 5px 5px',
                          marginTop: 0,
                          backgroundColor: Colors.container,
                        },
                      }}
                    >
                      <Flex width={'100%'} direction={'column'}>
                        <Flex
                          width={'100%'}
                          minHeight={100}
                          direction={'column'}
                          padding={'0px 30px 0px 30px'}
                        >
                          <Text
                            fontFamily="Inter-ExtraBold"
                            fontSize="24px"
                            fontWeight="600"
                            color="#000"
                            children={product.description}
                          />

                          <Text
                            marginTop={-10}
                            fontFamily="Inter"
                            fontSize="15px"
                            fontWeight="600"
                            color="#000"
                            children={product.description}
                          />
                        </Flex>

                        <View
                          border={0.5}
                          width={'100%'}
                          backgroundColor={'#e5e5e5'}
                          height={2}
                        />

                        <Flex
                          gap={7}
                          width={'100%'}
                          direction={'row'}
                          alignItems="center"
                          padding={'0px 30px 0px 30px'}
                        >
                          <Text
                            fontFamily="Inter-ExtraBold"
                            fontSize="24px"
                            fontWeight="600"
                            color="#000"
                            children={`$${_.first(product.prices).amount}`}
                          />
                          /
                          <Text
                            fontFamily="Inter"
                            fontSize="15px"
                            fontWeight="600"
                            color="#000"
                            children={`${_.first(product.prices).frequency}`}
                          />
                        </Flex>

                        {
                          <Flex
                            gap={10}
                            width={'100%'}
                            direction={'column'}
                            padding={'0px 30px 0px 30px'}
                          >
                            {product.metadata.map((meta, metaIdx) => {
                              if (meta.key === 'descDetail') {
                                return (
                                  <View key={metaIdx}>
                                    <Text
                                      fontFamily="Inter-Light"
                                      fontSize="17px"
                                      fontWeight="600"
                                      color="#000"
                                      children={meta.value}
                                    />
                                  </View>
                                );
                              }

                              return null;
                            })}
                          </Flex>
                        }

                        <View
                          position="absolute"
                          width={'100%'}
                          direction={'row'}
                          alignItems="center"
                          padding={'20px 30px 0px 30px'}
                          bottom={30}
                        >
                          <Button
                            variation="primary"
                            onClick={() => {
                              goToSubscribe(product);
                            }}
                            children={Strings.ENROLL_NOW}
                            width={'100%'}
                          />
                        </View>
                      </Flex>
                    </BlankOverviewBox>
                  );
                })}
              </Flex>
            </View>
          )}
        </Flex>

        <ToastContainer />
      </Flex>
      <Footer />
    </>
  );
}
